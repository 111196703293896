import React from 'react'
import myPicture from '../assets/ReinPicture.JPG'

const Introduction = () => {
  return (
    <div>
        <div name='Intro' className='h-screen w-full bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden my-auto'>

            <div className=' max-w-screen-lg px-4 mx-auto flex flex-col md:flex-row justify-center items-center h-full'>
                <div className='flex flex-col justify-center'>
                    <div>
                        <h2 className=' text-4xl md:text-6xl inline border-b-4 border-gray-200 p-0'>
                            Introduction 
                        </h2>

                    </div>

                    <p className=' text-gray-300 flex pt-9 max-w-md text-sm md:text-base'>
                        During my final year, I went under two internships with different companies where I was able to work in web development, where I was able to experience both front-end and back-end development.
                        This was the time I found my love for developing websites and web applications.
                    </p>

                    
                    <p className=' text-gray-300 flex pt-9 max-w-md text-sm md:text-base'>
                        My goal is to improve myself through expereince and projects where I can improve my current front-end and back-end development skills to eventually become a full-stack developer.
                        I am open and eager to try out new technologies and learn new skills to help me through my journey.
                    </p>
                </div>

                <div className=' pt-3'>
                    <img src={myPicture} alt='Me' className=' rounded-2xl mx-auto w-1/3 md:w-2/3'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Introduction