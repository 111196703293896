import Menu from "./components/Menu";
import Home from "./components/Home";
import Introduction from "./components/Introduction";
import TechStack from "./components/TechStack";
import Experience from "./components/Experience";
import Contact from "./components/Contact";

function App() {
  return (
    <div>
      <Menu />
      <Home />
      <Introduction />
      <TechStack />
      <Experience />
      <Contact />
    </div>
    
  );
}

export default App;
