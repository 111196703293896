import React from 'react'

const Contact = () => {
  return (
    <div name='Contact' className=' h-40 md:h-72 w-full bg-black'>
        <div className=' max-w-screen-lg px-4 mx-auto flex flex-col justify-center items-center'>
            <div className=' text-white font-bold md:text-xl text-sm'>
                Contact Me:
            </div>

            <div className=' w-full text-white flex flex-row justify-center py-3'>
                 <a className=' pl-4  md:text-xl text-sm' href='https://www.linkedin.com/in/rein-serrano/'>LinkedIn</a>
            </div>

            <div className=' w-full text-white flex flex-row justify-center py-3 md:text-xl text-sm'>
                Email:
                <div>
                    <p className=' pl-4 md:text-xl text-sm'>me@reinserrano.com</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Contact
