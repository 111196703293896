import React from 'react'
import ManulifeLogo from '../assets/Manulife-Logo.png'
import OllopaLogo from '../assets/Ollopa-Logo.PNG'

const Experience = () => {
  return (
    <div>
        <div name='Experience' className='h-screen w-full bg-gradient-to-b from-gray-900 to-black text-white my-auto overflow-hidden'>

            <div className=' max-w-screen-lg mx-auto flex flex-col justify-center items-center pt-4 md:pt-0 md:h-full'>
                <div className=' flex flex-col justify-center items-center'>
                        <h2 className=' text-4xl md:text-6xl inline border-b-4 border-gray-200'>
                            Experience
                        </h2>
                </div>
                <div className='flex flex-col md:flex-row w-full items-center pt-3'>
                    <div className=' w-1/2 flex justify-center items-center'>
                        <img src={ManulifeLogo} className=' bg-white h-20 md:h-40 rounded-md' alt='Manulife Logo'/>

                    </div>

                    <div className=' text-gray-300 flex flex-col pt-5 w-2/3 md:w-1/2 mx-auto'>

                        <h4 className=' font-bold  md:text-3xl text-xl'>
                            Manulife
                        </h4>

                        <p className='md:text-xl text-lg'>3 Months (2023)</p>
                        
                        <p className=' pt-2 text-sm md:text-base'>Worked on an internal project, where I was assigned to an AGILE team to work on a web application using MERN stack. I worked on both front-end using an internal css framework and used REST apis for the back end.</p>
                        <p className=' pt-2 text-sm md:text-base'>I was also taught how to apply automated unit testing using Jest. After it has passed said unit tests, my code can be then pushed to the main Github branch.</p>
                    </div>

                </div>

                <div className='flex flex-col md:flex-row w-full items-center pt-3'>
                    <div className=' w-1/2 flex justify-center items-center'>
                        <img src={OllopaLogo} className=' bg-white h-20 md:h-40 rounded-md' alt='Ollopa Logo'/>

                    </div>

                    <div className=' text-gray-300 flex flex-col pt-5 w-2/3 md:w-1/2 mx-auto'>

                        <h4 className=' font-bold  md:text-3xl text-xl '>
                            Ollopa Corporation
                        </h4>

                        <p className='md:text-xl text-lg'>1 Month (2022)</p>
                        
                        <p className=' pt-2 text-sm md:text-base'>Worked on the front-end of their e-commerce website. I was tasked to check if current functionalities work as intended and document them, in addition to checking if there are no UI errors like mispelling, awkard spaces, etc.</p>
                        <p className=' pt-2 text-sm md:text-base'>I was also tasked to research and design new possible layouts that can improve the user experience.</p>
                    </div>

                </div>

            </div>
        </div>
    </div>
  )
}

export default Experience