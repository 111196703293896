import React from 'react'
import  Card from './Card'

const TechStack = () => {
    const techStack = [
        {
            name: "frontend",
            list: ["HTML5", "CSS", "TailwindCSS", "Javascript", "React", "Bootstrap"]
        },
        {
            name: "backend",
            list: ["Node.JS", "express", "REST APIs", "Typescript"]
        },
        {
            name: "database",
            list: ["MySQL", "MongoDB"]
        },
    ]
  return (

    <div>
        <div name='Tech Stacks' className='h-screen w-full bg-gradient-to-b from-black to-gray-900 text-white'>

            <div className=' max-w-screen-lg px-4 mx-auto flex flex-col justify-center items-center md:h-full'>
                <div className='flex flex-col justify-center'>
                    <h2 className=' text-4xl md:text-6xl inline border-b-4 border-gray-200 pb-3'>
                        My Tech Stack
                    </h2>
                </div>
                <div className='flex flex-col md:flex-row w-full justify-center items-center pt-3'>
                    {
                        techStack.map(({name, list}) => {
                            return <div className=' px-3 py-3'><Card name={name} list={list}/></div>
                        })
                    }
                </div>

            </div>
        </div>
    </div>

  )
}

export default TechStack