import React, { useState } from 'react'
import {BsList} from 'react-icons/bs';
import {RxCross1} from 'react-icons/rx'
import myPicture from '../assets/ReinPicture.JPG'
import {Link} from 'react-scroll'

const Menu = () => {

    const [clicked, setClicked] = useState(false)

    const navItems = [
        {
            id:1,
            link:'Home'
        },
        {
            id:2,
            link:'Intro'
        },
        {
            id:3,
            link:'Tech Stacks'
        },
        {
            id:4,
            link:'Experience'
        },
        {
            id:5,
            link: 'Contact'
        }
    ]
  return (
    
    <div className='flex justify-between items-center w-full h-20 text-white fixed px-4 bg-black'>
        
        <div className='flex'>
            <img src={myPicture} alt= 'Me' className='rounded-full h-16' />
            <h1 className='text-5xl pl-3 py-3'>       
                Rein
            </h1>
        </div>

        <ul className='hidden md:flex'>

            {navItems.map(({id, link}) => {
                return (<li key={id} className='px-4 cursor-pointer hover:scale-125 hover:text-white duration-200 text-gray-300'>
                     <Link to={link} smooth duration={500}>{link}</Link>
                </li>) 
            })}
        </ul>
        
        <div onClick={() => setClicked(!clicked)} className='z-10 cursor-pointer pr-5 hover:scale-125 duration-200 text-gray-300 md:hidden'>
            {clicked ? <RxCross1 size={40}/> : <BsList size={40}/>}
        </div>
        
            <ul className={`flex flex-col justify-center items-center absolute h-screen w-2/3 top-0 right-0 bg-gradient-to-b from-black to-gray-800 md:hidden rounded-md 
            ${clicked ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-150`}>
                {navItems.map(({id, link}) => {
                    return (<li key={id} className='px-4 pt-3 cursor-pointer hover:scale-125 hover:text-white duration-200 text-gray-300 text-4xl'>
                        

                        <Link to={link} smooth duration={500}  onClick={() => setClicked(!clicked)}>{link}</Link>
                    </li>) 
                })}                
            </ul>
        



    </div>
  )
}

export default Menu