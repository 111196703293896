import React from 'react'

const Home = () => {
  return (
    <div name='Home' className='h-screen w-full bg-gradient-to-b from-black to-gray-900 text-white'>

        <div className=' max-w-screen-lg p-4 mx-auto flex flex-col justify-center items-center w-full h-full'>
            <div>
                <h2 className=' text-6xl inline text-yellow-200 p-0 font-bold'>
                    Hi! My Name is Rein Serrano!
                </h2>
            </div>

            <p className=' text-white text-lg flex pt-9'>
                    A recent graduate of Ateneo de Manila University who likes web development and has a few months of experience under his belt from internships. I love building web applications and I am eager to learn new things along the way.
            </p>
        </div>
    </div>
  )
}

export default Home