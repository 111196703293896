import React from 'react'

const Card = (props) => {
    const techSector = props
    console.log(techSector.list[1])
  return (
    <div className=' rounded-md bg-black shadow-md shadow-slate-900 md:h-72 md:w-52 h-52 w-32'>
        <div className='flex flex-col justify-center items-center pt-5'>
            <h4 className=' font-bold capitalize md:text-lg text-sm'>{props.name + ":"}</h4>
            <div className=' capitalize'>
                {techSector.list.map( item => {
                    return <div className=' pt-2 flex flex-col justify-center items-center md:text-base text-xs'><p>{item}</p></div> 
                })}
            </div>
        </div>
    </div>
  )
}

export default Card